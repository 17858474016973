//progress bar
import parse from 'html-react-parser'
import React, { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
// import { LangContext } from "../../../App";
import Button from "../../../components/design/buttons/Button";
import RangeSlider from "../../../components/design/radius-7/radius";
import Radius from "../../../components/design/radius/radius";
import { getProviders } from '../../../services/providers.service';
import { toAbsoluteUrl } from "../../../tools/AssetHelpers";
import PlanBar from "../../plans/components/planBar";
import './Step.css'
import { RiCheckboxCircleFill} from 'react-icons/ri';


const ProgressBar = ({ onProgressEnd,time }: any) => {
    const [progress, setProgress] = React.useState(0)

  
    React.useEffect(() => {
      let loadingTimeout = setTimeout(() => {
        // if (loading >= 100) return
        if (progress >= 100) return
        setProgress(progress + 1)
      }, time/100)
  
      if (progress === 100) {
        onProgressEnd()
      }
  
      return () => {
        clearTimeout(loadingTimeout)
      }
    }, [progress])
  
    const size = 32
    const trackWidth = 3
    const trackColor = `#ddd`
    const indicatorWidth = 5
    const indicatorColor = `#3eb6ae`
    const indicatorCap = `round`
    const spinnerMode = false
    const spinnerSpeed = 1
  
    const center = size / 2,
          radius = center - (trackWidth > indicatorWidth ? trackWidth : indicatorWidth),
          dashArray = 2 * Math.PI * radius,
          dashOffset = dashArray * ((100 - progress) / 100)
  
    if(dashOffset === 0) {
        return (
            <RiCheckboxCircleFill className='c-primary' size={size}/>
        )
    }
    return (
      <>
        <div
          className="svg-pi-wrapper"
          style={{ width: size, height: size }}
        >
          <svg
            className="svg-pi" 
            style={{ width: size, height: size }}
          >
            <circle
              className="svg-pi-track"
              cx={center}
              cy={center}
              fill="transparent"
              r={radius}
              stroke={trackColor}
              strokeWidth={trackWidth}
            />
            <circle
              className={`svg-pi-indicator ${
                spinnerMode ? "svg-pi-indicator--spinner" : ""
              }`}
              style={{ animationDuration: spinnerSpeed * 1000 + "" }}
              cx={center}
              cy={center}
              fill="transparent"
              r={radius}
              stroke={indicatorColor}
              strokeWidth={indicatorWidth}
              strokeDasharray={dashArray}
              strokeDashoffset={dashOffset}
              strokeLinecap={indicatorCap}
            />
          </svg>
        </div>
      </>
    )
  }
  
const Step = ({step,currentStep, steps, nextStep, prevStep,lang}: {step:any,currentStep: number, steps: number, nextStep: (value:any) => void, prevStep: () => void, lang:string}) => {
    const {t,i18n}= useTranslation();
    const progress = (currentStep / steps) * 100;
    const navigate = useNavigate();
    const [radius1, setRadius1] = React.useState(0);
    const [radius2, setRadius2] = React.useState(0);
    const [show, setShow] = React.useState(1);
    const [array, setArray] = React.useState<number[]>([]);
    const [int, setInt] = React.useState(0);
    const [last_state, setLastState] = React.useState<any>(
        [{
            radius1: 0,
            radius2: 0,
            array: [],
            int: 0,
            currentStep: currentStep
        }]
    );
    const submetToNextStep = () => {
        
        setLastState([...last_state, {
            radius1: radius1,
            radius2: radius2,
            array: array,
            int: int,
            currentStep: currentStep
        }]);
        let choice;
        // alert(step.type);
        if(step.type === 'multiselect' || step.type === 'api') {
            choice = {
                questionRefrence: step.questionRefrence,
                value: array.length ? array : null
            }
        } else if(step.type === 'range') {
            // const items = step.items;

            // check if the value is in the range of the items and return the item index

            // const val = items.findIndex((item:any) => {
            //     return item.from <= radius1 && item.to >= radius2;
            // });
            // choice = {
            //     questionRefrence: step.questionRefrence,
            //     // value: ((radius1 + radius2) ? [radius1, radius2] : null)
            //     value: 
            //     step.items.filter((item:any) => item.from === radius1 && item.to === radius2).map((item:any) => item.id) ?
            //     step.items.filter((item:any) => item.from === radius1 && item.to === radius2).map((item:any) => item.id) : null
            // }
            // alert(choice.value);
            // alert(choice.value);
            choice = {
                questionRefrence: step.questionRefrence,
                value: int ? [int] : null
            }
        } else if(step.type === 'select') {
            choice = {
                questionRefrence: step.questionRefrence,
                value: int ? [int] : null
            }
        }
        else if(step.type === 'text') {
            choice = {
                questionRefrence: step.questionRefrence,
                value: int ? [
                    "+"+
                    int
                ] : int === -1 ? ["0"] : ["0"]
            }
        }
        else {
            choice = null
        }

        

        setRadius1(0);
        setRadius2(0);
        nextStep(choice);
    }

    const [disabled, setDisabled] = React.useState(true);


    const [providers, setProviders] = React.useState<any>([]);

    const fetchProviders = async () => {
        const response = await getProviders(i18n.language);
        setProviders(response.returnData);
    }

    useEffect(() => {
        // console.log('last_state', last_state);
        // console.log('step', step);
        // console.log('currentStep', currentStep);
        if(last_state.filter((item:any) => item.currentStep === currentStep).length && currentStep != 1) {
            // alert('last_state');
            setRadius1(last_state.filter((item:any) => item.currentStep === currentStep)[0].radius1);
            setRadius2(last_state.filter((item:any) => item.currentStep === currentStep)[0].radius2);
            setArray(last_state.filter((item:any) => item.currentStep === currentStep)[0].array);
            setInt(last_state.filter((item:any) => item.currentStep === currentStep)[0].int);
        } else {
            setRadius1(0);
            setRadius2(0);
            setInt(0);
            fetchProviders();
            if(step.type === 'api') {
                setArray(step.items.map((item:any) => item.id));
            } else {
                setArray([]);
            }
        }
        setShow(1);
    }, [currentStep])

    useEffect(() => {
        if(step.type === 'text') {
            
            setDisabled(false);
            return;
        }
        if(radius1 || radius2 || int || array.length) {
            setDisabled(false);
        } else {
            setDisabled(true);
        }
    }, [radius1, radius2, int, array])

    if(!step) 
    return  <div className="row mt-4">
    <div className="col-lg-12">
        <div className="d-flex justify-content-center">
            <div className="spinner-border " role="status">
                <span className="visually-hidden">
                    {/* Loading... */}
                    </span>
            </div>
        </div>
    </div>

</div>;


    const {title,hint,type,items,min,max,step:step1, courancy} = step;
   
    return (
        
        currentStep != 1?
        <div className='p-2 p-lg-0'>
        <div className='p-1 p-lg-0'>
           
                    <h1 className=" col-lg-8 mt-1 pb-1 mt-lg-5 pb-lg-3">
                        {title}    
                    </h1>
                    
                    {hint && 
                        <p className="text-muted   pb-3">
                        {hint}
                    </p>}
                    <div className="row my-3" >
                        {
                            type === "select" ? (
                                    items.map((item: any, index: number) => (
                                    <div className="col-lg-3 col-md-6 col-sm-12" key={index}>
                                        <label className="check_box my-2">
                                            <input type="radio" name="radio" value={item.questionChoiceRefrence} 
                                            checked={int === item.questionChoiceRefrence}
                                            onChange={(e) => {setInt(item.questionChoiceRefrence)}} />
                                            <span className="mx-3">{item.label}</span>
                                        </label>
                                    </div>
                                ))
                            ) : type === "multiselect" ? (
                                items.map((item: any, index: number) => (
                                    // if it is more than 6 items, make two items in one row
                                    <div className={"col-lg-3 col-md-6" + (items.length > 6 ? " col-6" : "")}
                                     key={index}>
                                        <label className="check_box my-2 p-lg-4 p-3 align-items-center " style={{minHeight: '80px'}} >
                                            <input type="checkbox" value={item.questionChoiceRefrence}
                                            checked={array && array.includes(item.questionChoiceRefrence)}
                                            onChange={(e) => {setArray(array && array.includes(item.questionChoiceRefrence) ? array.filter((item_: number) => item_ !== item.questionChoiceRefrence) : [...array, item.questionChoiceRefrence])}} />
                                            <span className="mx-1"></span>
                                            <span className="">{item.label}</span>
                                        </label>
                                    </div>
                                ))
                            ) : type === "api" ? (
                                items
                                .sort((a: any, b: any) => a.name.length - b.name.length)
                                .map((item: any, index: number) => (
                                    <div className="col-lg-3 col-md-6 col-6" key={index} >
                                        <label className="d-flex align-items-center check_box my-2 justify-content-between px-2 px-lg-4">
                                            <span className="d-flex align-items-center">
                                            <input type="checkbox" value={item.id}
                                            checked={array && array.includes(item.id)}
                                            onChange={(e) => {setArray(array && array.includes(item.id) ? array.filter((ii: number) => ii !== item.id) : [...array, item.id])}} />
                                            <span className="mx-2 mx-lg-3"
                                            >{item.name}</span>
                                            </span>
                                        <img src={item.imagePathSmall} width="32" height="32"  style={{borderRadius: "6px"}} />
                                        </label>
                                    </div>
                                ))
                            ) : type === "range" ? (
                                <div className="col-lg-12 ">
                                    {/* <input type="range" min={min} max={max}  step={step1} /> */}
                                    {/* <Radius min={min} max={max} step={step1} radius={value} onChange={(e) => {setValue(e)}} courancy={courancy} /> */}
                                    <RangeSlider
                                    rtl={lang === "ar"}
                                    from={radius1}
                                    to={radius2}
                                    step={step1}
                                    min={min}
                                    max={max}
                                    courancy={courancy}
                                    onChange={(newRadius1, newRadius2) => {
                                        setRadius1(newRadius1);
                                        setRadius2(newRadius2);
                                        //find the item that has the same from and to and return the index
                                        const val = items.findIndex((item:any) => {
                                            return item.from === newRadius1 && item.to === newRadius2;
                                        }
                                        );
                                        setInt(items[val].questionChoiceRefrence);


                                    }}
                                    disabled={true}
                                    buttons={items}
                                    />
                                </div>
                            ) : type === "infolist" ? (
                                <div className="col-lg-12">
                                    {
                                    // array from number of show
                                        Array.from(Array(show))
                                        .map((item: any, index: number) => (
                                            <div className="d-flex" key={index}>
                                               <div style={{width: "36px", height: "36px"}}>
                                               <ProgressBar onProgressEnd={() => {
                                                    console.log("onProgressEnd");
                                                    if(items.length > show)
                                                      setShow(show+1);
                                                }} time={items[index][1]} />
                                                  </div>
                                                {/* <svg width="14" height="10" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M2.48409 2.08595C6.79065 2.0861 8.74573 3.17365 11.4151 7.05188" stroke="#3EB6AE" stroke-width="4" stroke-linecap="round"/>
                                                </svg> */}
                                                    <p className="text-muted mx-3 ">
                                                        {items[index][0]}
                                                    </p>
                                            </div>
                                        ))
                                    }
                                </div>
                            ) : type === "text" ? (
                                
                                <div className="col-lg-6 col-12">
                                    <input type="text" className="form-control form-control-lg"
                                    value={
                                        int === 0 ? "" : int === -1 ? "" : 
                                        int
                                    }
                                    onChange={(e) => {
                                        if(e.target.value === "") {
                                            setInt(0);
                                        }
                                        else if(e.target.value === "-") {
                                            setInt(-1);
                                        }
                                        else if(!Number.isNaN(parseInt(e.target.value))) {
                                            setInt(parseInt(e.target.value));
                                        }
                                    }}
                                
                                    />
                                </div>
                            ):
                            
                            null



                        }
                    </div>
                    
                        
                    <div className="row mt-4 " style={{minHeight: "42px"}}>
                        {
                            currentStep === steps ? null : (
                                !disabled ? (
                             <div className="col-lg-4 d-none d-lg-flex" style={{    width: "284px",
                                height: "72px"
                             }}>
                            <Button inner={lang === "ar" ? "التالي" : "Next"}
                             onClick={submetToNextStep}
                                color="primary" isfullWidth={true} />
                                </div> 
                                ) : (
                                    <div className="col-lg-4 d-none d-lg-flex" style={{    width: "284px",
                                    height: "72px",
                                    opacity: "0.5"
                                }}>
                                    <Button inner={lang === "ar" ? "التالي" : "Next"}
                                        onClick={() => {}}
                                        color="primary" isfullWidth={true} />
                                    </div>
                                    
                                )
                            )
                        }
                    </div>
                
                    {/* show only on mobile */}
                    <div className="row mt-4 d-lg-none position-fixed bottom-0 start-0 end-0 p-4" style={{background: "linear-gradient(0deg, rgba(255,255,255,1) 50%, rgba(255,255,255,0) 100%)"}}>
                    {
                            currentStep === steps ? null : (
                                !disabled ? (
                             <div className="col-lg-4">
                            <Button inner={lang === "ar" ? "التالي" : "Next"}
                             onClick={submetToNextStep}
                                color="primary" isfullWidth={true}  />
                                </div> 
                                ) : (
                                    <div className="col-lg-4" style={{    opacity: "0.5"}}>
                                    <Button inner={lang === "ar" ? "التالي" : "Next"}
                                        onClick={() => {}}
                                        color="primary" isfullWidth={true} isDisabled={true} />
                                        </div>
                                        
                                )

                                
                            )
                        }
                        


                    </div>


                    

                
        </div>
        </div>
        :  <div className="row p-3 p-lg-0">
                 <div className="col-lg-6">
                     <h1 className="">
                     {parse(t("stepper.title"))}
                     </h1>
                     <p className="text-muted ">
                     {t("stepper.subtitle")}

                     </p>
                     
                     <div className="row mt-4">
                         <div className="col-lg-6 mb-3">
                             <Button inner={t("stepper.get_started")} onClick={()=>{
                                    submetToNextStep();
                                    localStorage.removeItem("output");
                                    setLastState([

                                        {
                                            
                                            radius1: 0,
                                            radius2: 0,
                                            array: [],
                                            int: 0,
                                            currentStep: currentStep
                                        }
                                    ]);
                             }
                                }   
                             color="primary" isfullWidth={true} />
                         </div>
                            
                         <div className="col-lg-6">
                         {/* <Link to="/about">About</Link> */}

                             <Button inner={t("stepper.see_plans")} onClick={()=>navigate('/plans')}
                             color="secondary" isfullWidth={true} />
                         </div>

                         <div className="col-12 mt-4">
                             <div className="row row-cols-5 row-cols-lg-5">
                                 {
                                    providers.map((provider: any, index: number) => (
                                        <div className={"col col-lg-2 p-2 p-lg-3"}
                                        key={index}>
                                            <Button inner={
                                            <img src={toAbsoluteUrl(provider.imagePathSmall)} alt="plan" className="img-fluid" style={{borderRadius: "6px"}} />
                                        } isDisabled={true} color="secondary p-2 p-md-2 p-lg-2 p-xl-3" isfullWidth={true} />
                                        </div>
                                    ))
                                 }
                             </div>
                         </div>

                     </div>       
                 </div>              
                 <div className="col-lg-6 position-relative d-none d-lg-block overflow-hidden" style={{overflow: "visible !important",zIndex: "-1"}}>
                     <div className="bg-elements position-absolute">
                     <div className="" >
                         <svg width="888" height="888" viewBox="0 0 888 888" fill="none" xmlns="http:www.w3.org/2000/svg">
                         <g opacity="0.5">
                         <path opacity="0.2" d="M608.765 297.265L578.388 263.317C544.72 225.69 493.476 208.981 444.1 219.528L399.55 229.044" stroke="#EB5BE5" stroke-width="6"/>
                         <path opacity="0.04" d="M605.61 298.958L605.618 298.967L636.134 332.79C669.236 369.479 680.149 421.088 664.734 468.036L650.522 511.318L650.519 511.329L636.485 554.669C621.262 601.68 582.024 636.936 533.658 647.06L489.07 656.393L489.057 656.395L444.508 665.911C396.183 676.234 346.032 659.881 313.081 623.056L282.704 589.108L282.696 589.099L252.18 555.276C219.079 518.587 208.165 466.978 223.581 420.03L237.792 376.748L237.796 376.737L251.829 333.398C267.052 286.386 306.29 251.131 354.656 241.007L399.245 231.673L399.257 231.671L443.806 222.155C492.131 211.832 542.282 228.186 575.233 265.01L605.61 298.958Z" stroke="#EB5BE5" stroke-width="6"/>
                         <path opacity="0.04" d="M669.48 241.401L669.484 241.405L732.747 311.525C766.209 348.613 777.241 400.784 761.658 448.244L732.196 537.971L732.194 537.977L703.101 627.824C687.712 675.348 648.047 710.987 599.154 721.221L506.717 740.57L506.711 740.572L414.354 760.3C365.503 770.734 314.806 754.203 281.496 716.978L218.521 646.599L218.517 646.595L155.254 576.475C121.792 539.387 110.759 487.216 126.343 439.756L155.805 350.029L155.806 350.023L184.9 260.176C200.289 212.652 239.954 177.013 288.847 166.779L381.284 147.43L381.29 147.428L473.647 127.7C522.497 117.265 573.195 133.797 606.504 171.022L669.48 241.401Z" stroke="#EB5BE5" stroke-width="3"/>
                         <path opacity="0.2" d="M732.062 538.51L702.968 628.358C687.414 676.393 647.321 712.416 597.901 722.761L505.464 742.11" stroke="#EB5BE5" stroke-width="3"/>
                         <path opacity="0.2" d="M285.993 595.588L342.537 658.254C356.345 673.556 377.773 681.93 397.823 677.153V677.153" stroke="#EB5BE5" stroke-width="6.58471"/>
                         <path opacity="0.2" d="M620.274 669.08C590.44 669.08 566.255 645.319 566.255 616.008" stroke="#EB5BE5" stroke-width="6"/>
                         <path opacity="0.2" d="M206.126 546.826C176.292 546.826 152.106 523.065 152.106 493.754" stroke="#EB5BE5" stroke-width="6"/>
                         <rect width="7.40884" height="7.40884" rx="2" transform="matrix(0.939693 -0.34202 -0.34202 -0.939693 255.524 282.06)" fill="#9AC8FF"/>
                         </g>
                         </svg>
                     </div>
                     </div>
                     <div className="elements">
                         <div className="circle">
                             {/* <i className="fas fa-check"></i> */}
                             <img src={toAbsoluteUrl('/media/svgs/sim.svg')} alt="plan" className="img-fluid" />

                         </div>
                         <div className="circle">
                             {/* <i className="fas fa-search"></i> */}
                             <img src={toAbsoluteUrl('/media/svgs/search.svg')} alt="plan" className="img-fluid" />
                         </div>
                         <div className="circle">
                             {/* <i className="fas fa-globe-americas"></i> */}
                                <img src={toAbsoluteUrl('/media/svgs/cpu.svg')} alt="plan" className="img-fluid" />
                         </div>
                         <div className="circle">
                             {/* <i className="fas fa-wifi"></i> */}
                             <img src={toAbsoluteUrl('/media/svgs/globe.svg')} alt="plan" className="img-fluid" />
                         </div>
                         <div className="circle">
                             {/* <i className="fas fa-phone"></i> */}
                             <img src={toAbsoluteUrl('/media/svgs/phone.svg')} alt="plan" className="img-fluid" />

                         </div>

                         <div className="circle">
                             {/* <i className="fas fa-phone"></i> */}
                             <img src={toAbsoluteUrl('/media/svgs/tag.svg')} alt="plan" className="img-fluid" />

                         </div>
                     </div> 

                 </div>
             </div>
        
    );

};

export default Step;
export {ProgressBar};